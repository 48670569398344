export const navbarNavigations = [
	{
		name: "Projects",
		url: "/projects",
	},
	{
		name: "Events",
		url: "/events",
	},
	{
		name: "Contact Us",
		url: "/contact",
	},
];
